import { combineReducers } from "redux";
import isSideNavOpen from "./side-nav-reducer";
import expandCollapseClick from "./expand-collapse-reducer";
import authentication from "./authentication-reducer";
import recurrenceRule from "./recurrence-rule-reducer";


// import runScheduleJSON from "./run-schedule-JSON-reducer";

const rootReducer = combineReducers({
  authentication,
  isSideNavOpen,
  expandCollapseClick,
  recurrenceRule,
});

export default rootReducer;
