import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { LogLevel, Logger } from "msal";
import { Configurations } from "../App.global";

const logger = new Logger(
  (logLevel, message, containsPii) => {
    // console.log("[MSAL]", message);
  },
  {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false,
  }
);

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: Configurations.authority,
      clientId: Configurations.clientId,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,//window.location.origin,"/getAToken"
      validateAuthority: true,
     // knownAuthorities:["https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47","https://management.core.windows.net/"],  //claimsRequest:"",
      

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false,
    },
    
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
      logger: logger as any,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  {
    scopes: [Configurations.scope],//Configurations.user_imp_scope, Configurations.user_read_scope
  },
  {
    loginType: LoginType.Popup,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin + "/auth.html",
  }
);
