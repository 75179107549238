import * as React from "react";
import { initializeIcons } from "@uifabric/icons";
import "./dashboard.style.scss";
import assistantIcon from "../../assests/image/jpg/assistantIcon.jpg";
import dashboardIimage from "../../assests/image/jpg/Dashboard-image.jpg";
import backImage from "../../assests/image/jpg/currency1.jpg";
import Loading from "../common/loading/loading";
import { IconButton, Avatar } from "@material-ui/core";
initializeIcons();

export class Dashboard extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <>
        <div>
          <img src={backImage} className="backImage" />
        </div>
        <div className="bg-text">
          <h1>Welcome to Tax Engineering Assistant</h1>
          <h1>One stop for all Engineering tools and Utilities</h1>
          <br></br>
          <h2>Havent found anything useful yet?? </h2>
          <h2>Please upgrade me with your ideas and use me</h2>
        </div>
        {/* <div className="dashboard">
          <div className="dashboard-message">
            <IconButton edge="start" color="inherit" aria-label="menu">
              <img src={assistantIcon} className="assistant" />
            </IconButton>
            <h2>Welcome to Tax Engineering Assistant</h2>
            <h3>One stop for all the Tax Team tools and utilities</h3>

            <h4>Havent found anything useful yet?? </h4>
            <h4>Please upgrade me with your ideas and use me</h4>
          </div>
        </div> */}
      </>
    );
  }
}

export default Dashboard;
