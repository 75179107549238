import { getNugetList, getRepoList } from "./nuget-data-parser";

// Algo
//1. Call Nuget api to retrieve all nugets and filter it oit based on domain
//2. Call repo list api
//2.1 For each repo we just get csproj and sol details
//2.2 Check if csproj is nuget by comparing the nuget name retrived with repo file names
//3. Maintain list of nuget and non-nuget repo list
//3.1 Repos conatining nugets are part of nuget repo
//4. Repos may conatin single or mutiple soln
//5. Divide them into single sol and muti sol
//6. Check if repos id nuget type or not
//7.For single sol repo: mark nuget if csproj has nuget marked
//7.1 then group all csproj file and put it under one solution.
//7.2 mark sol as isnuget
//8.For multi sol Repo
//8.1 group cs proj files to a solution
//8.2 also mark the sol as nuget or not
//8.3 separate the nuget and non nuget repo from multirepo
//******* */
//9. Find nugets used in repo
//9.1 For non nuget repo , find the list of nugets used and maintain its list
//9.2 For nuget repos
//9.2.1 separate nuget proj and non nuget proj
//10. get nuget dep for nuget repo
//11. get nuget dep for non nuget repo
//12. Edge case : proj which has nuget and this proj is being referred by nuget proj

export function getComponentData(bearerToken) {
  return new Promise((resolve, reject) => {
    getNugetList(bearerToken)
      .then((nugetDataFromAPI) => {
        getRepoList(bearerToken)
          .then((repoDataFromAPI) => {
            var nugetRepoDetailList = [];
            var nonNugetRepoDetailList = [];
           

            repoDataFromAPI.forEach((eachRepoFromAPI) => {
              markNugetCSProjFiles(nugetDataFromAPI, eachRepoFromAPI);

              //SINGLE SOL REPO logic
              // Building nuget repo and non-nuget repo list
              // map csproj and sol files
              singleSolRepoFHandling(
                eachRepoFromAPI,
                nugetRepoDetailList,
                nonNugetRepoDetailList
              );

              multiSolRepoHandling(
                eachRepoFromAPI,
                nugetRepoDetailList,
                nonNugetRepoDetailList
              );
            });

            // for non -nuget repo, get nuget dep List
            getNugetDependciesListForNonNugetRepo(
              nugetDataFromAPI,
              nonNugetRepoDetailList
            );

            // for nuget repo
            // get nuget proj and non nuget proj
            getNugetDependeciesListForNugetRepos(
              nugetRepoDetailList,
              nugetDataFromAPI
            );

            // Final cleaned data
            // Nuget data creation
            var finalNugetData = [];
            getFinalNugetData(finalNugetData, nugetDataFromAPI);

            //Non-Nuget Repo list:
            var finalNonNugetRepoList = [];
            getFinalNonNugetRepoList(
              finalNonNugetRepoList,
              nonNugetRepoDetailList
            );

            var finalNugetRepoList = [];
            getFinalNugetrepoList(finalNugetRepoList, nugetRepoDetailList);

            resolve({
              nugetData: finalNugetData,
              nonNugetRepoList: finalNonNugetRepoList,
              nugetRepoList: finalNugetRepoList,
            });
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function markNugetCSProjFiles(nugetDataFromAPI, eachRepoFromAPI) {
  eachRepoFromAPI.csProjFiles.forEach((csprojFile) => {
    // set default Values
    csprojFile.isNuget = false;
    csprojFile.nugetId = "";

    // check if file has nuget name
    //TODO: remove extra if and no need of demo
    nugetDataFromAPI.forEach((nug) => {
      if (csprojFile.isNuget == false) {
        if (csprojFile.path.includes(nug.name + ".csproj")) {
          csprojFile.isNuget = true;
          csprojFile.nugetId = nug.key;
        }
      }
    });
  });
}

export function singleSolRepoFHandling(
  eachRepoFromAPI,
  nugetRepoDetailList,
  nonNugetRepoDetailList
) {
  if (eachRepoFromAPI.solFiles.length == 1) {
    let nugetCsProjFiles = eachRepoFromAPI.csProjFiles.filter((cs) => {
      return cs.isNuget;
    });

    eachRepoFromAPI.solFiles[0].isNugetSol = nugetCsProjFiles.length > 0;
    eachRepoFromAPI.solFiles[0].csProjFiles = eachRepoFromAPI.csProjFiles;

    delete eachRepoFromAPI.csProjFiles;
    // separte the list
    // MARK IT AS NUGET OR NON NUGET REPO
    if (nugetCsProjFiles.length > 0) {
      nugetRepoDetailList.push(eachRepoFromAPI);
    } else {
      nonNugetRepoDetailList.push(eachRepoFromAPI);
    }
  }
}

export function groupCSProjFileToSol(eachRepoFromAPI) {
  //Group csproj files to a solution
  // and mark sol as nuget and non nuget
  eachRepoFromAPI.solFiles.forEach((s) => {
    s.csProjFiles = [];
    s.isNugetSol = false;
    let solName = s.path.split("/")[1];
    eachRepoFromAPI.csProjFiles.forEach((cs) => {
      if (cs.path.includes(solName)) {
        if (cs.isNuget) {
          s.isNugetSol = true;
        }
        s.csProjFiles.push(cs);
      }
    });
  });
  delete eachRepoFromAPI.csProjFiles;
}
export function multiSolRepoHandling(
  eachRepoFromAPI,
  nugetRepoDetailList,
  nonNugetRepoDetailList
) {
  //Group csproj files to a solution
  // and mark sol as nuget and non nuget
  if (eachRepoFromAPI.solFiles.length > 1) {
    groupCSProjFileToSol(eachRepoFromAPI);

    //separate the nuget and non nugetlist
    let nugetSolList = eachRepoFromAPI.solFiles.filter((v) => {
      return v.isNugetSol == true;
    });
    if (nugetSolList.length > 0) {
      nugetRepoDetailList.push({
        id: eachRepoFromAPI.id,
        name: eachRepoFromAPI.name,
        url: eachRepoFromAPI.url,
        solFiles: nugetSolList,
      });
    }

    let nonNugetSolList = eachRepoFromAPI.solFiles.filter((v) => {
      return v.isNugetSol == false;
    });
    if (nonNugetSolList.length > 0) {
      nonNugetRepoDetailList.push({
        id: eachRepoFromAPI.id,
        name: eachRepoFromAPI.name,
        url: eachRepoFromAPI.url,
        solFiles: nonNugetSolList,
      });
    }
  }
}

export function getNugetDependciesListForNonNugetRepo(
  nugetDataFromAPI,
  nonNugetRepoDetailList
) {
  nonNugetRepoDetailList.forEach((nonNugetRepoRecord) => {
    nonNugetRepoRecord.repodepIdList = new Set();

    nonNugetRepoRecord.solFiles.forEach((nonNugetSolFile) => {
      nonNugetSolFile.soldepIdList = [];

      nonNugetSolFile.csProjFiles.forEach((nonNugetCsProjFile) => {
        nonNugetCsProjFile.dep.forEach((d) => {
          for (let index in nugetDataFromAPI) {
            let nugetItem = nugetDataFromAPI[index];
            if (d.includes(nugetItem.name)) {
              // add in sol dep
              let solIndex = nonNugetSolFile.soldepIdList.findIndex((i) => {
                return i == nugetItem.key;
              });
              if (solIndex == -1) {
                nonNugetSolFile.soldepIdList.push(nugetItem.key);
              }
              nonNugetRepoRecord.repodepIdList.add(nugetItem.key);
              break;
            }
          }
        });
      });
    });
  });
}

export function getNugetDependeciesForNugetCSProj(
  nugetProjs,
  nugetrepoReord,
  nugetDataFromAPI
) {
  // get depedency of nuget with other nuget
  nugetProjs.forEach((prjrecord) => {
    // add the nuget also for listing in repo ******
    nugetrepoReord.repodepIdList.add(prjrecord.nugetId);

    prjrecord.depIdList = new Set();
    prjrecord.dep.forEach((d) => {
      for (let index in nugetDataFromAPI) {
        let nugetItem = nugetDataFromAPI[index];
        if (d.includes(nugetItem.name)) {
          prjrecord.depIdList.add(nugetItem.key);
          nugetrepoReord.repodepIdList.add(nugetItem.key); // ********
        }
      }
    });
  });
}

export function getNugetDependeciesForNonNugetCSProj(
  nonNugetProjs,
  nugetDataFromAPI,
  nugetrepoReord,
  nugetProjs
) {
  nonNugetProjs.forEach((nonnugetprjrecord) => {
    nonnugetprjrecord.depIdList = new Set();
    nonnugetprjrecord.dep.forEach((d) => {
      for (let index in nugetDataFromAPI) {
        let nugetItem = nugetDataFromAPI[index];
        if (d.includes(nugetItem.name)) {
          nonnugetprjrecord.depIdList.add(nugetItem.key);
          nugetrepoReord.repodepIdList.add(nugetItem.key); // *******
        }
      }

      // if there are dep in this project , check if this project itself
      // is dep to nuget prj

      if (nonnugetprjrecord.depIdList.size > 0) {
        let spiltArr = nonnugetprjrecord.path.split("/");
        let projNameNormal = spiltArr[spiltArr.length - 1].replace(
          ".csproj",
          ""
        );
        nugetProjs.forEach((prjrecord) => {
          prjrecord.dep.forEach((d) => {
            if (d.includes(projNameNormal)) {
              //add this proj repos in the
              // nonnugetprjrecord.depIdList
              nonnugetprjrecord.depIdList.forEach((ditem) => {
                prjrecord.depIdList.add(ditem);
              });
            }
          });
        });
      }
    });
  });
}

export function getNugetDependeciesListForNugetRepos(
  nugetRepoDetailList,
  nugetDataFromAPI
) {
  nugetRepoDetailList.forEach((nugetrepoReord) => {
    nugetrepoReord.repodepIdList = new Set();

    nugetrepoReord.solFiles.forEach((sol) => {
      // nuget prj
      var nugetProjs = sol.csProjFiles.filter((prj) => {
        return prj.isNuget == true;
      });
      // non-nuget prj
      var nonNugetProjs = sol.csProjFiles.filter((prj) => {
        return prj.isNuget == false;
      });

      getNugetDependeciesForNugetCSProj(
        nugetProjs,
        nugetrepoReord,
        nugetDataFromAPI
      );

      // get nuget depency for non-nuget proj
      getNugetDependeciesForNonNugetCSProj(
        nonNugetProjs,
        nugetDataFromAPI,
        nugetrepoReord,
        nugetProjs
      );

      //update the nuget data with deps:
      nugetProjs.forEach((prjrecord) => {
        var resultData = nugetDataFromAPI.filter((n) => {
          return n.key == prjrecord.nugetId;
        });
        if (resultData.length > 0) {
          resultData[0].dep = prjrecord.depIdList;
        }
      });
    });

    // for nuget projects
  });
}

export function getFinalNugetData(finalNugetData, nugetDataFromAPI) {
  nugetDataFromAPI.forEach((n) => {
    let depArray = [];
    if (n.dep != null || n.dep != undefined) {
      n.dep.forEach((v) => {
        // also cover edge case
        if (n.key != v) depArray.push(v);
      });
    }

    finalNugetData.push({
      id: n.key,
      name: n.name,
      repoName: "",
      link: "",
      dependency: depArray, //n.dep ?? [],
    });
  });
}

export function getFinalNonNugetRepoList(
  finalNonNugetRepoList,
  nonNugetRepoDetailList
) {
  nonNugetRepoDetailList.forEach((n) => {
    finalNonNugetRepoList.push({
      id: n.id,
      repoName: n.name,
      solutionName: "",
      link: "",
      dependency: n.repodepIdList,
    });
  });
}

export function getFinalNugetrepoList(finalNugetRepoList, nugetRepoDetailList) {
  nugetRepoDetailList.forEach((n) => {
    finalNugetRepoList.push({
      id: n.id,
      repoName: n.name,
      solutionName: "",
      link: "",
      dependency: n.repodepIdList,
    });
  });
}
