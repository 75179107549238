import * as React from "react";

import { initializeIcons } from "@uifabric/icons";

import "./loading.style.scss";

import { Spinner, SpinnerSize } from "office-ui-fabric-react";

import spinnerImage from "../../../assests/image/svg/toggleIcon.svg";





initializeIcons();



export class Loading extends React.Component<{}, {}> {

  constructor(props: {}) {

    super(props);



    this.state = {};

  }



  public render(): JSX.Element {

    return (

      <>

        <div className="message">

          <div>

            <div className="image-container"><img src={spinnerImage} className="spinner-image" /></div>

            <div>Take a sip and relax..</div>

            <Spinner />

          </div>

        </div>

      </>

    );

  }

}



export default Loading;