export async function handleResponse(response: any) {
  if (response.status === 203 || response.status === 302) { return response }
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error: any) {
  console.error("API call failed. " + error);
  throw error;
}
