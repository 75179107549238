import * as React from "react";
import { Typography } from "@material-ui/core";
import "./playlist-component.scss";
import IframeComponent from "../iframe/iframe-component";

export default function PlaylistComponent(data: any) {
	return (
		<div className="display">
			<Typography variant="h5" color="inherit">
				{data.name}
			</Typography>

			<div className="video-display">
				{data.videoList.map((videodata: any) => {
					return (
						<IframeComponent
							src={videodata.link}
							name={videodata.name}
						></IframeComponent>
					);
				})}
			</div>
		</div>
	);
}
