import { handleResponse, handleError } from "../../../api/api.util";

export function GetSwaggerLinkData() {
	return fetch("https://teauiservice.azurewebsites.net/api/Swagger/Get", {
		method: "GET",
		headers: {
			"content-type": "application/json",
			"Access-Control-Allow-Headers":
				"Access-Control-Allow-Headers,Content-Type",
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function GetLaunchPadData() {
	return fetch("https://teauiservice.azurewebsites.net/api/LaunchPad/Get", {
		method: "GET",
		headers: {
			"content-type": "application/json",
			"Access-Control-Allow-Headers":
				"Access-Control-Allow-Headers,Content-Type",
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
