import { handleResponse, handleError } from "../../../api/api.util";

const ActivateRolesBaseUrl = "https://teauiservice.azurewebsites.net/api/";

let counter = 0;
function createData(
	role: string,
	resource: string,
	resourcetype: string,
	membership: string,
	condition: string,
	endtime: string
) {
	counter += 1;
	return {
		id: counter,
		role,
		resource,
		resourcetype,
		membership,
		condition,
		endtime,
	};
}
export function GetRolesData() {
	const dataArray = [
		createData(
			"Contributor",
			"11814-OneTax-DIT-01",
			"Resource group",
			"Group",
			"None",
			"11/11/2021, 11:30:42 AM"
		),
		createData(
			"Contributor",
			"11814-OneTax-SIT-01",
			"Resource group",
			"Group",
			"None",
			"3/28/2022, 5:01:24 PM"
		),
		createData(
			"Contributor",
			"11814-OneTax-UAT-01",
			"Resource group",
			"Group",
			"None",
			"11/11/2021, 11:30:42 AM"
		),
		createData(
			"Reader",
			"11814-OneTax-Prod-01",
			"Resource group",
			"Group",
			"None",
			"11/11/2021, 11:30:42 AM"
		),
		createData(
			"Reader",
			"11814-OneTax-DIT-01",
			"Resource group",
			"Group",
			"None",
			"1/5/2022, 11:19:30 AM"
		),
		createData(
			"Reader",
			"11814-OneTax-SIT-01",
			"Resource group",
			"Group",
			"None",
			"1/5/2022, 11:19:30 AM"
		),
		createData(
			"Reader",
			"11814-OneTax-UAT-01",
			"Resource group",
			"Group",
			"None",
			"1/5/2022, 11:19:30 AM"
		),
	];
	return dataArray;
}

export function ActivateSelectedRoles() {
	window.open("_blank");
}
