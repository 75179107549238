import * as React from "react";
import {
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	CardActions,
	Typography,
	Avatar,
	Button,
} from "@material-ui/core";
import "./cardcomponent.scss";

export default function CardComponent(data: any) {
	return (
		<div className="display">
			<Card className="card">
				<CardHeader className="text" title={data.title} />
				<CardMedia
					className="media"
					image={data.image}
					title={data.imagetitle}
				/>
				<CardContent className="text">
					<Typography variant="body2" color="textSecondary" component="p">
						<h3>{data.description}</h3>
					</Typography>
				</CardContent>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						window.open(data.url, "_blank");
					}}
					className="text"
				>
					Launch
				</Button>
			</Card>
		</div>
	);
}
