import React from "react";
import PlaylistComponent from "../../common/playlist/playlist-component";
import { trainingVideosList } from "./training-videos-data";
import "./training-videos.scss";

export default function TrainingVideosComponent() {
	return (
		<div className="flex-container">
			{trainingVideosList.map((data) => {
				return (
					<PlaylistComponent
						name={data.name}
						videoList={data.videoList}
					></PlaylistComponent>
				);
			})}
		</div>
	);
}
