import React from "react";
import clsx from "clsx";
import {
	makeStyles,
	useTheme,
	Theme,
	createStyles,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import toggleIcon from "./../../../assests/image/svg/toggleIcon.svg";
import "./application-container.scss";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import menuList from "./application-menu-data";
import BuildIcon from "@material-ui/icons/Build";
import { Link } from "react-router-dom";
import ComponentContainer from "../component-container/component-container";
import { green } from "@material-ui/core/colors";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LaunchIcon from "@material-ui/icons/Launch";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Work from "@material-ui/icons/Work";
import { Settings, Business } from "@material-ui/icons";
import AccountContext from "../../../auth/account-context";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		appBar: {
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			//background: "skyblue",
			width: drawerWidth,
		},
		drawerHeader: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
			justifyContent: "flex-end",
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginLeft: -drawerWidth,
		},
		contentShift: {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		},
	})
);

export default function ApplicationContainerComponent() {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const [localTime, setLocalTime] = React.useState("");
	const [pstTime, setPSTTime] = React.useState("");
  const identity:any  = React.useContext(AccountContext);

	// const getTime = () => {
	//   var d = new Date();
	//   var utc = d.getTime() + d.getTimezoneOffset() * 60000;

	//   var pstOffset: any = "-7";
	//   var pst: any = new Date(pstOffset * 3600000 + utc);

	//   setLocalTime(d.toLocaleTimeString());
	//   setPSTTime(pst.toLocaleTimeString());
	// };
	// const timer = setInterval(getTime, 1000);

	// const getPSTTime = () => {
	//   var d = new Date();
	//   setPSTTime(d.toLocaleTimeString());
	// };
	// const timerPST = setInterval(getPSTTime, 1000);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const getIcon = (iconName: string) => {
		switch (iconName) {
			case "BuildIcon":
				return <BuildIcon style={{ color: green[500] }} />;
			case "MarketPlaceIcon":
				return <ShoppingCart style={{ color: green[500] }} />;
			case "OnboardingKitIcon":
				return <Work style={{ color: green[500] }} />;
			case "ZeroDriIcon":
				return <Settings style={{ color: green[500] }} />;
			case "BusinessIcon":
				return <Business style={{ color: green[500] }} />;
			default:
				return <BuildIcon style={{ color: green[500] }} />;
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<img src={toggleIcon} className="toggle-side-image" />
					</IconButton>
					<Link to="/home" className="link">
						<Typography variant="h6" noWrap>
							Tax Engineering | Assistant
						</Typography>
					</Link>
          <div className="timer-details">
          <Typography variant="subtitle1" noWrap>
          {identity.userName}
						</Typography>
          </div>

					{/* <div className="timer-details">
            <div className="timer-items">
              <Typography variant="h6" noWrap>
                Local time : {localTime}
              </Typography>
            </div>
            <div className="timer-items">
              <Typography variant="h6" noWrap>
                PST time : {pstTime}
              </Typography>
            </div>
          </div> */}
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.drawerHeader}>
					<IconButton onClick={handleDrawerClose}>
						<img src={toggleIcon} className="toggle-side-image" />
					</IconButton>
				</div>
				<Divider />

				<div className="menu">
					{menuList.map((menu) => {
						return (
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<ListItemIcon>{getIcon(menu.icon ?? "default")}</ListItemIcon>

									<div className="menu-text-color">{menu.rootPath}</div>
								</AccordionSummary>

								<AccordionDetails>
									<List>
										{menu.routeList.map((data: any) => (
											<>
												<ListItem button key={data.key}>
													<Link className="menu-link" to={data.url}>
														<div className="menu-text-color">{data.name}</div>
													</Link>
												</ListItem>
												{/* <Divider /> */}
											</>
										))}
									</List>
								</AccordionDetails>
							</Accordion>
						);
					})}
				</div>
			</Drawer>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				<div className={classes.drawerHeader} />
				<ComponentContainer />
			</main>
		</div>
	);
}
