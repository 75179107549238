export interface ITrainingLink {
  id : string;
  category: string;
  links: ILink[];
}

export interface ILink {
  id: string;
  name: string;
  link: string;
  type: ILinkType;
}

export enum ILinkType {
  video,
  document,
}
