import { authProvider } from "./auth-provider";

export const GetToken = async () => {
  let authToken = "";
  const tokenDetails = await authProvider.getAccessToken();
  authToken = "Bearer " + tokenDetails.accessToken;
  debugger
  return authToken;
};

export const GetDevOpsToken = async () => {
  let authToken = "";
  const tokenDetails = await authProvider.getAccessToken();
  authToken = "Bearer " + tokenDetails.accessToken;
  return authToken;
};
