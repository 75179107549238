import * as React from "react";
import { initializeIcons } from "@uifabric/icons";
import "./under-construction.style.scss";
import { FontIcon } from "office-ui-fabric-react";
import UnderConstructionImage from "../../../assests/image/jpg/under-construction.jpg";

initializeIcons();

export class UnderConstruction extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <>
        <div className="construction-message">
          <img src={UnderConstructionImage} className="construction-image" />
        </div>
        <div></div>
      </>
    );
  }
}

export default UnderConstruction;
