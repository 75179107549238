import { getFormattedDateInString } from "./javascript-functions";

export function utcToBrowserTimeZone(utcDateTime: Date) {
  const utcOffset = new Date().getTimezoneOffset();
  let localDateTime = new Date(utcDateTime);
  localDateTime.setMinutes(localDateTime.getMinutes() - utcOffset);
  return localDateTime;
}

export function browserToUTCTimeZone(localDateTime: Date) {
  // const utcOffset = new Date().getTimezoneOffset();
  let utcDateTime = new Date(localDateTime);
  // utcDateTime.setMinutes(utcDateTime.getMinutes() + utcOffset);
  return utcDateTime;
}

export function getLocalTimeZone() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
}

export function utcToAnyTimeZone(utcDateTime: Date, utcOffset: number) {
  utcDateTime.setMinutes(utcDateTime.getUTCMinutes() + utcOffset);
  return utcDateTime;
}

export function formatDateTime(date?: Date) {
  if (date) {
    const options = {
      // weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: getLocalTimeZone(),
      timeZoneName: "short",
    };
    return new Date(date).toLocaleTimeString("en-us", options);
  }
  return "";
}

export function formatDate(date: Date) {
  if (date) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-us", options);
  }
  return "";
}

export function formatTime(localDateTime: Date) {
  return new Date(localDateTime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getTimeIn24HourFormat(date: any): string {
  let time = new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
  if (time.split(":")[0] === "24") {
    time = "00:" + time.split(":")[1];
  }
  return time;
}

export function getDateAndTimeInLocalTime(date: Date): string {
  return getFormattedDateInString(date) + " " + getTimeIn24HourFormat(date);
}
