import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Fab,
} from "@material-ui/core";
//import { nugets, nonNugetRepoList } from "./nuget-data";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import "./nuget.scss";
import LinkIcon from "@material-ui/icons/Link";
import Loading from "../../common/loading/loading";
import { getComponentData } from "./nuget-business-algo";


const NugetComponent = () => {
  const [selectedNugetUI, onSelectedChange] = React.useState({});
  const [directNugetsUI, newDirectAffectedNuget] = React.useState([]);
  const [indirectNugetsUI, newInDirectNuget] = React.useState([]);
  const [nonNugetReposUI, newNonNugetRepoName] = React.useState([]);
  const [nugetReposUI, newNugetRepoName] = React.useState([]);

  const [filteredNugetListFromAPI, onGettingAllNugets] = React.useState([]);
  const [
    filteredNonNugetRepoListFromAPI,
    onGettingAllNonNugetRepos,
  ] = React.useState([]);
  const [filteredNugetRepoListFromAPI, onGettingAllNugetRepos] = React.useState(
    []
  );
  const [loading, loadingState] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [onLoad, onLoadState] = React.useState(true);


  const start = () => {
    loadingState(true);
    getComponentData(token)
      .then((fullData) => {
        onGettingAllNugets(fullData.nugetData);
        onGettingAllNonNugetRepos(fullData.nonNugetRepoList);
        onGettingAllNugetRepos(fullData.nugetRepoList);
        loadingState(false);
      })
      .catch((e) => {
        loadingState(false);
      });
  };

  if (onLoad) {
    start();
    onLoadState(false);
  }


  const onValueChange = () => {
    var selectedid = selectedNugetUI;
    var idList = [];
    let directAffectedNuget = [];
    let indirectAffectedNuget = [];
    let nonNugetRepoName = [];

    let nugetRepoName = [];

    // this would direct and indirect
    idList = getDependentNugets(selectedid);

    separateDirectAndIndirect(
      idList,
      directAffectedNuget,
      indirectAffectedNuget
    );

    //Based on nuget dependecies lidt, find nuget repos affted
    handleNugetRepoList(selectedid, nugetRepoName, idList);

    //Based on nuget dependecies lidt, find non nuget repos affted
    handleNonNugetRepoList(selectedid, nonNugetRepoName, idList);

    newDirectAffectedNuget(directAffectedNuget);
    newInDirectNuget(indirectAffectedNuget);
    newNonNugetRepoName(nonNugetRepoName);
    newNugetRepoName(nugetRepoName);
  };

  /// get all the direct and indirect nugets
  const getDependentNugets = (selectedid) => {
    // direct
    var dependecies = [];
    var depIncludedCheck = [];
    var nugetCount = 0;
    var depIncludedCheckTrueCount = 0;

    dependecies = fetchDirectDependencies(selectedid);

    // populate default list
    filteredNugetListFromAPI.forEach((a) => {
      depIncludedCheck.push({ id: a.id, present: false, isDirect: false });
    });

    // direct dependencies
    depIncludedCheck.forEach((a) => {
      if (
        dependecies.findIndex((c) => {
          return c == a.id;
        }) != -1
      ) {
        a.present = true;
        a.isDirect = true;
      }
    });

    nugetCount = filteredNugetListFromAPI.length;
    depIncludedCheckTrueCount = depIncludedCheck.filter((a) => {
      return a.present == true;
    }).length;

    // base conditions
    if (
      depIncludedCheckTrueCount == 0 ||
      nugetCount == depIncludedCheckTrueCount
    ) {
      return dependecies;
    } else {
      // indirect dependecies
      fetchIndirectDependices(depIncludedCheck, nugetCount);
    }

    return depIncludedCheck.filter((a) => {
      return a.present == true;
    });
  };

  // Recursive approach to find indirect dep
  // using the dependecnies of the direct ones

  const fetchIndirectDependices = (depIncludedCheck, nugetCount) => {
    // base condition for the recusrion
    if (
      depIncludedCheck.filter((a) => {
        return a.present == true;
      }).length == nugetCount
    ) {
      return depIncludedCheck;
    }

    // get list of dependencies which are not present
    var depNotInclude = depIncludedCheck.filter((a) => {
      return a.present == false;
    });

    var isIndirectDepPresent = false;

    if (depNotInclude != undefined) {
      // check for the dependencies of all nuget which were marked false(not part of direct)
      // compare with the ones who were marked direct
      depNotInclude.forEach((a) => {
        var dep = filteredNugetListFromAPI.filter((x) => {
          return x.id == a.id;
        })[0];

        // compare dependecnies with direct nuget
        // if yes mark it as indirect
        // and run recursively again
        dep.dependency.forEach((c) => {
          if (
            depIncludedCheck.findIndex((d) => {
              return d.id == c && d.present == true && c != selectedNugetUI;
            }) != -1
          ) {
            var item = depIncludedCheck.filter((x) => {
              return x.id == a.id;
            })[0];
            item.present = true;
            isIndirectDepPresent = true;
          }
        });
      });
      // if any one nuget is part of indirect
      // call agin recurively
      if (isIndirectDepPresent) {
        fetchIndirectDependices(depIncludedCheck, nugetCount);
      } else {
        return depIncludedCheck;
      }
    }
  };

  //Nuget will have dependcies
  // search in each nuget depencies and create the direct list
  const fetchDirectDependencies = (selectedNugetId) => {
    var directDependecies = [];
    filteredNugetListFromAPI.forEach((nuget) => {
      let selectedId = -1;
      selectedId = nuget.dependency.find((b) => {
        return b == selectedNugetId;
      });

      if (selectedId != undefined && selectedId != -1) {
        var index = directDependecies.findIndex((c) => {
          return c == nuget.id;
        });
        if (index == -1) {
          directDependecies.push(nuget.id);
        }
      }
    });
    return directDependecies;
  };

  const separateDirectAndIndirect = (
    idList,
    directAffectedNuget,
    indirectAffectedNuget
  ) => {
    if (idList != null || idList != undefined)
      idList.forEach((d) => {
        if (d.isDirect == true) {
          directAffectedNuget.push(
            filteredNugetListFromAPI.filter((e) => {
              return e.id == d.id;
            })[0]
          );
        } else {
          indirectAffectedNuget.push(
            filteredNugetListFromAPI.filter((e) => {
              return e.id == d.id;
            })[0]
          );
        }
      });
  };

  const handleNugetRepoList = (selectedid, nugetRepoName, idList) => {
    filteredNugetRepoListFromAPI.forEach((a) => {
      a.dependency.forEach((b) => {
        if (
          selectedid == b &&
          nugetRepoName.findIndex((e) => {
            return e.repoName == a.repoName;
          }) == -1
        ) {
          nugetRepoName.push(a);
        } else {
          if (
            idList.findIndex((c) => {
              return c.id == b;
            }) != -1 &&
            nugetRepoName.findIndex((e) => {
              return e.repoName == a.repoName;
            }) == -1
          ) {
            nugetRepoName.push(a);
          }
        }
      });
    });
  };

  const handleNonNugetRepoList = (selectedid, nonNugetRepoName, idList) => {
    filteredNonNugetRepoListFromAPI.forEach((a) => {
      a.dependency.forEach((b) => {
        if (
          selectedid == b &&
          nonNugetRepoName.findIndex((e) => {
            return e.repoName == a.repoName;
          }) == -1
        ) {
          nonNugetRepoName.push(a);
        } else {
          if (
            idList.findIndex((c) => {
              return c.id == b;
            }) != -1 &&
            nonNugetRepoName.findIndex((e) => {
              return e.repoName == a.repoName;
            }) == -1
          ) {
            nonNugetRepoName.push(a);
          }
        }
      });
    });
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      width: 300,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "red",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));
  const classes = useStyles();

  const handleChange = (event) => {
    onSelectedChange(event.target.value);
  };

  return (
    <>
      {loading && <Loading></Loading>}
      {filteredNugetListFromAPI.length > 0 &&
        <div className="nuget-container">
          <div className="dependency-list">
            <div className="nuget-header">
              <div>
                <InputLabel htmlFor="filled-age-native-simple">
                  Select Nuget{" "}
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={selectedNugetUI}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  {filteredNugetListFromAPI.map((a) => {
                    return (
                      <MenuItem value={a.id}>{a.name.split(".")[3]}</MenuItem>
                    );
                  })}
                </Select>
                &nbsp; &nbsp; &nbsp;
                <Fab color ="primary" variant="circular" onClick={onValueChange}>
                   Used By
                </Fab>
              </div>
            </div>
            <br />
            <br />


            <div className="result">


            <div className="data-list">
                {nugetReposUI.length > 0 && (
                  <div>
                    <InputLabel
                      htmlFor="filled-age-native-simple"
                      className="nuget-label"
                    >
                      <b>Repositories </b>
                      {nugetReposUI.length > 0 && (
                        <span>(count {nugetReposUI.length})</span>
                      )}
                    </InputLabel>
                    <br />
                    <div className="nuget-list-div repo-name">
                      {nugetReposUI.map((a) => {
                        return (
                          <List>
                            <ListItem>
                              <ListItemIcon>
                                <LinkIcon />
                              </ListItemIcon>
                              <b>{a.repoName}</b>
                            </ListItem>
                          </List>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>


              <div className="data-list ">
                {directNugetsUI.length > 0 && (
                  <div>
                    <InputLabel
                      htmlFor="filled-age-native-simple"
                      className="nuget-label"
                    >
                      <b>Nugets(Direct)</b>
                      {directNugetsUI.length > 0 && (
                        <span>(count {directNugetsUI.length})</span>
                      )}
                    </InputLabel>
                    <br />
                    <div className="nuget-list-div direct">
                      <List>
                        {directNugetsUI.map((a) => {
                          return (
                            <>
                              <ListItem>
                                <ListItemIcon>
                                  <LinkIcon />
                                </ListItemIcon>
                                <b>{a.name.split(".")[3]}</b>
                              </ListItem>
                            </>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                )}
                {indirectNugetsUI.length > 0 && (
                  <div>
                    <InputLabel
                      htmlFor="filled-age-native-simple"
                      className="nuget-label"
                    >
                      <b>Nugets(InDirect)</b>
                      {indirectNugetsUI.length > 0 && (
                        <span>(count {indirectNugetsUI.length})</span>
                      )}
                    </InputLabel>
                    <br />
                    <div className="nuget-list-div indirect">
                      <List>
                        {indirectNugetsUI.map((a) => {
                          return (
                            <>
                              <ListItem>
                                <ListItemIcon>
                                  <LinkIcon />
                                </ListItemIcon>
                                <b>{a.name.split(".")[3]}</b>
                              </ListItem>
                            </>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                )}
              </div>

              

              <div className="data-list">
                {directNugetsUI.length >0 && indirectNugetsUI.length && nonNugetReposUI.length > 0 && (
                  <div>
                    <InputLabel
                      htmlFor="filled-age-native-simple"
                      className="nuget-label"
                    >
                      <b>Other Repositories</b>
                      {nonNugetReposUI.length > 0 && (
                        <span>(count {nonNugetReposUI.length})</span>
                      )}
                    </InputLabel>
                    <br />
                    <div className="nuget-list-div repo-name">
                      {nonNugetReposUI.map((a) => {
                        return (
                          <List>
                            <ListItem>
                              <ListItemIcon>
                                <LinkIcon />
                              </ListItemIcon>
                              <b>{a.repoName}</b>
                            </ListItem>
                          </List>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default NugetComponent;
