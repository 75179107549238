import {
  getNugets,
  getRepo,
  getcsProjContent,
  getAllFilesForRepo,
} from "./nuget-service";

//get overall repo list from Finance artifacts
export function getNugetList(bearerToken) {
  return getNugets(bearerToken).then((x) => {
    var list = [];
    var key = 0;
    x.value.forEach((data) => {
      key = key + 1;
      let nugetId = data.id;
      let name = data.name;
      let latestVersion = "NA";
      let v = data.versions.filter((a) => {
        return a.isLatest == true;
      });
      if (v.length > 0) {
        latestVersion = v[0].normalizedVersion ?? "NA";
      }

      // Filters
      if (
        name.includes(".TAP") &&
        !(
          name.includes("TAPUnitOfWork") ||
          name.includes("TAPEventPublisherLibrary") ||
          name.includes("TAPHiveUtility")
        )
      ) {
        list.push({ key, nugetId, name, latestVersion });
      }
    });
    return list;
  });
}

// get all repos for tax team
export function getRepoList(bearerToken) {
  return getRepo(bearerToken)
    .then((data) => {
      let list = [];
      data.value.forEach((a) => {
        let id = a.id;
        let name = a.name;
        let url = a.url;

        // filter only tap repo and exclude TAPUI
        if (
          name.includes("taxrp") &&
          (name.includes("TAP") || name.includes("Recon")) &&
          name != "FIN-FFS-taxrp-TAPUI"
        ) {
          list.push({ id, name, url });
        }
      });
      return new Promise((resolve, reject) => {
        let newList = [];

        return list.forEach((data) => {
          return getFilesForEachRepos(bearerToken, data).then((z) => {

            newList.push(z);
            if (newList.length == list.length) {
              return resolve(newList);
            }
          });
        });
      });

      //return list;
    })
    .catch((e) => {});
}

//get files for all repos and filter out soln/proj files
const getFilesForEachRepos = (bearerToken, a) => {
  return getFileDetails(bearerToken, a.id).then((pathList) => {
    return new Promise((resolve, reject) => {
      //let id = a.id;
      let solFiles = pathList.filter((f) => {
        return f.includes(".sln");
      });
      let csProjFiles = pathList.filter((f) => {
        return f.includes(".csproj");
      });

      a.solFiles = [];
      a.csProjFiles = [];
      let solId = 1;
      solFiles.forEach((s) => {
        a.solFiles.push({ id: solId, path: s });
        solId = solId + 1;
      });

      let csprojId = 1;
      csProjFiles.forEach((c) => {
        let cs = { id: csprojId, path: c };
        csprojId = csprojId + 1;
        getDepenedencyListforcsprojList(bearerToken, a.id, cs).then((d) => {

          cs = d;
          a.csProjFiles.push(cs);

          if (a.csProjFiles.length == csProjFiles.length) {
            resolve(a);
          }
        });
      });
    });
  });
};

//
export function getFileDetails(bearerToken, id) {
  return getAllFilesForRepo(bearerToken, id).then((data) => {
    let pathList = [];
    data.value.forEach((a) => {
      pathList.push(a.path);
    });
    return pathList;
  });
}

// parse csproj and external reference file to get nuget dependcies
export function getDepenedencyListforcsprojList(bearerToken, id, r) {

  return getcsProjContent(bearerToken, id, r.path).then((data) => {

    if (data) {
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(data, "text/xml");

      // External References
      var hinPath = xmlDoc.getElementsByTagName("HintPath");
      // Project References
      var projectReference = xmlDoc.getElementsByTagName("Name");

      var dep = [];
      if (hinPath != null && hinPath != undefined) {
        for (let node of hinPath) {
          let val = node.childNodes[0].nodeValue;
          // filter only tap dep
          if (val.includes(".TAP")) {
            dep.push(val);
          }
        }
      }
      if (projectReference != null && projectReference != undefined) {
        for (let node of projectReference) {
          let val = node.childNodes[0].nodeValue;
          // filter only tap dep
          if (val.includes(".TAP")) {
            dep.push(val);
          }
        }
      }
      r.dep = dep;
      return r;
    }
  }).catch(e=>{

  });
}
