import React, { useState } from "react";
import {
  AzureAD,
  AuthenticationState,
  IAzureADFunctionProps,
} from "react-aad-msal";
import "./App.scss";
import { Provider } from "react-redux";
import { Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import ComponentContainer from "./components/shell/component-container/component-container";
import configureStore, { AppState } from "./redux/configureStore";
import { authProvider } from "./auth/auth-provider";
import AccountContext from "./auth/account-context";
import ApplicationContainerComponent from "./components/shell/application-container/application-container";

const App = () => {
  // Refactor initial state config
  const state: AppState = {
    expandCollapseClick: false,
    isSideNavOpen: false,
    authentication: {
      initializing: false,
      initialized: false,
      idToken: null,
      accessToken: null,
      state: AuthenticationState.Unauthenticated,
    },
    recurrenceRule: [],
  };
  const store = configureStore(state);

  const authenticate = (
    authenticationState: any,
    accountInfo: any,
    login: any
  ) => {
    if (authenticationState === AuthenticationState.Unauthenticated) {
      login();
      return <></>;
    } else if (authenticationState === AuthenticationState.Authenticated) {
      return (
        <AccountContext.Provider value={accountInfo.account}>
          <Route path="/" component={ApplicationContainerComponent}></Route>
        </AccountContext.Provider>
      );
    }
  };

  return (
    <div className="App">
      <Provider store={store}>
        <Router history={createBrowserHistory()}>
          <AzureAD provider={authProvider} reduxStore={store}>
            {({
              accountInfo,
              authenticationState,
              login,
              error,
            }: IAzureADFunctionProps) => {
              return (
                <React.Fragment>
                  {authenticate(authenticationState, accountInfo, login)}
                </React.Fragment>
              );
            }}
          </AzureAD>

          {/* <Route path="/" component={ApplicationContainerComponent}></Route> */}
          {/* <Redirect from="/" to="/home" /> */}
        </Router>
      </Provider>
    </div>
  );
};

export default App;
