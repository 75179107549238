import React, { useEffect } from "react";
import { GetTrainingData } from "./training-service";
import {
	Box,
	Button,
	makeStyles,
	Card,
	CardContent,
	Typography,
	CardActions,
	Divider,
	MenuItem,
	ListItem,
	ListItemIcon,
	Link,
} from "@material-ui/core";
import "./training.scss";
import MovieIcon from "@material-ui/icons/Movie";
import { ILinkType, ITrainingLink } from "./model/ITraining";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Loading } from "../../common/loading/loading";

const useStyles = makeStyles({
	root: {
		width: 280,
		height: 500,
		padding: 8,
		margin: 24,
		background: "#f7df9c",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});
var firstPageLoadTrainingData: React.SetStateAction<never[]> = [];
var firsttime = true;
export default function TrainingComponent() {
	const classes = useStyles();

	const [TrainingData, setTrainingData] = React.useState([]);
	const [loading, loadingState] = React.useState(false);
	useEffect(() => {
		if (firsttime) {
			loadingState(true);
			GetTrainingData()
				.then((data: any) => {
					loadingState(false);
					setTrainingData(data);
					firstPageLoadTrainingData = data;
				})
				.catch((e) => loadingState(false));
			firsttime = false;
		} else setTrainingData(firstPageLoadTrainingData);
	}, []); // GET request only once, no need to compute everytime

	return (
		<>
			{loading && <Loading></Loading>}
			{!loading && (
				<div className="training-div">
					{TrainingData.map((cat: ITrainingLink) => {
						return (
							<Card className={classes.root}>
								<CardContent>
									<Typography
										className={classes.title}
										color="textSecondary"
										gutterBottom
									>
										<h3>{cat.category}</h3>
										<Divider />
									</Typography>
									{cat.links.map((a) => {
										return (
											<ListItem>
												<ListItemIcon>
													{a.type == ILinkType.video && <MovieIcon />}
													{a.type == ILinkType.document && <AssignmentIcon />}
												</ListItemIcon>
												<Link
													href={a.link}
													target="_blank"
													className="link-list"
												>
													{a.name}
												</Link>
											</ListItem>
										);
									})}
								</CardContent>
							</Card>
						);
					})}
				</div>
			)}
		</>
	);
}
