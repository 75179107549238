import { IThirdPartyHelper } from "./model/IThirdPartyHelperModel";

export const devToolsThirdPartyHelperList: IThirdPartyHelper[] = [
	{
		name: "SQL Formatter",
		description:
			"Format and Beautify your SQL code data and make it Easy to Read",
		link: "https://codebeautify.org/sqlformatter",
		imageurl: "https://sqlformatter.org/common-pages/icon/sql.png",
		imagetitle: "SQL Formatter",
	},
	{
		name: "JSON Viewer",
		description:
			"A simple and easy way to Edit, View, Analyse and Format JSON data",
		link: "https://jsonviewer.com/",
		imageurl:
			"http://a5.mzstatic.com/us/r30/Purple4/v4/7b/69/dd/7b69ddc1-32d6-6072-0d10-f80d16089e1b/Icon.175x175-75.png",
		imagetitle: "JSON Viewer",
	},
	{
		name: "Text Compare",
		description:
			"Compare your Texts and Find the Differences between them highlighted",
		link: "https://text-compare.com/",
		imageurl:
			"https://www.differencebetween.com/wp-content/uploads/2016/10/Difference-Between-Text-and-Discourse-2.png",
		imagetitle: "Text Compare",
	},
	{
		name: "UTC Time Converter",
		description:
			"An effective tool to show the Time Difference between UTC Time Zones",
		link: "https://www.utctime.net/utc-time-zone-converter",
		imageurl:
			"https://cdn.iconscout.com/icon/premium/png-512-thumb/time-limit-5-770936.png",
		imagetitle: "UTC Time Converter",
	},
	{
		name: "WHO Plus",
		description:
			"Find People, Explore Communities and Discover your Interests at Microsoft",
		link: "https://whoplus.microsoft.com/",
		imageurl:
			"https://flyclipart.com/thumb2/add-friends-friends-instagram-icon-with-png-and-vector-format-884561.png",
		imagetitle: "WHO Plus",
	},
];
