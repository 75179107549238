export const Configurations = {
  //authority: "____authority____",
  appInsightsKey: "",
  aadResource: "",
  aadClientId: "",

  environment: "dev",
  baseURL: "https://tapuiservicedit.azurewebsites.net/api",
  clientId: "3245c1b0-1ab3-4ae7-8e94-db74215fbcdf",
  scope: "3245c1b0-1ab3-4ae7-8e94-db74215fbcdf/readAccess", //"api://3245c1b0-1ab3-4ae7-8e94-db74215fbcdf/readAccess",
  user_imp_scope:
    "api://3245c1b0-1ab3-4ae7-8e94-db74215fbcdf/user_impersonation",
  user_read_scope: "api://3245c1b0-1ab3-4ae7-8e94-db74215fbcdf/User.Read",
  authority:
    "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
};
