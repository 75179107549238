import * as React from "react";
import "./activate-roles.scss";
import {
	Button,
	Checkbox,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { GetRolesData, ActivateSelectedRoles } from "./activate-roles-service";

const tableHeaderLabels = [
	{ id: "role", disablePadding: true, label: "Role" },
	{ id: "resource", disablePadding: false, label: "Resource" },
	{ id: "resourceType", disablePadding: false, label: "Resource Type" },
	{ id: "membership", disablePadding: false, label: "Membership" },
	{ id: "condition", disablePadding: false, label: "Condition" },
	{ id: "endtime", disablePadding: false, label: "EndTime" },
];
class TableHeaderComponent extends React.Component<any, any> {
	render() {
		const { onSelectAllClick, numSelected, rowCount } = this.props;
		return (
			<TableHead>
				<TableRow>
					<TableCell padding="checkbox">
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
					</TableCell>
					{tableHeaderLabels.map((row: any) => {
						return (
							<TableCell
								className="tableCell"
								key={row.id}
								padding={row.disablePadding ? "none" : "default"}
							>
								{row.label}
							</TableCell>
						);
					})}
				</TableRow>
			</TableHead>
		);
	}
}

export default class ActivateRolesComponent extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			selected: [],
			rolesData: [],
		};
	}

	componentDidMount() {
		const dataArray = GetRolesData();
		this.setState({ rolesData: dataArray });
	}

	handleSelectAllClick = (event: any, checked: any) => {
		if (checked) {
			this.setState((state: any) => ({
				selected: state.rolesData.map((n: any) => n.id),
			}));
			return;
		}
		this.setState({ selected: [] });
	};

	handleClick = (
		event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
		id: number
	) => {
		const { selected } = this.state;
		const selectedIndex = selected.indexOf(id);
		let newSelected: any[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		this.setState({ selected: newSelected });
	};
	isSelected = (id: number) => this.state.selected.indexOf(id) !== -1;

	activateRoles() {
		ActivateSelectedRoles();
	}

	render() {
		const { rolesData, selected } = this.state;
		return (
			<>
				<Paper className="root">
					<TableContainer className="tableWrapper">
						<Table className="table" aria-labelledby="tableTitle">
							<TableHeaderComponent
								numSelected={selected.length}
								rowCount={rolesData.length}
								onSelectAllClick={this.handleSelectAllClick}
							/>
							<TableBody>
								{rolesData.map((n: any) => {
									const isSelected = this.isSelected(n.id);
									return (
										<TableRow
											hover
											onClick={(event) => this.handleClick(event, n.id)}
											role="checkbox"
											aria-checked={isSelected}
											tabIndex={-1}
											key={n.id}
											selected={isSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox checked={isSelected} />
											</TableCell>
											<TableCell component="th" scope="row" padding="none">
												{n.role}
											</TableCell>
											<TableCell>{n.resource}</TableCell>
											<TableCell>{n.resourcetype}</TableCell>
											<TableCell>{n.membership}</TableCell>
											<TableCell>{n.condition}</TableCell>
											<TableCell>{n.endtime}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
				<div className="flex-container">
					<Button
						variant="contained"
						color="primary"
						onClick={this.activateRoles}
						disabled={selected.length > 0 ? false : true}
					>
						Activate
					</Button>
					<Typography className="text">
						{selected.length} Roles Selected
					</Typography>
				</div>
			</>
		);
	}
}
