import { handleResponse, handleError } from "../../../api/api.util";


const nugetUrl =
  "https://microsoftit.feeds.visualstudio.com/_apis/Packaging/Feeds/17ad5efb-7f06-48e8-9209-8d3878f244c7/Packages?packageNameQuery=TAX&includeDescription=true&%24top=100&includeDeleted=false";

// Create or Update an activity
export function getNugets(bearerToken: string) {
    return fetch(nugetUrl, {
        method: "GET", // POST for create, PUT to update when id already exists.
        headers: {
          Authorization: bearerToken,
          "content-type": "application/json",
          'Access-Control-Allow-Headers': "Access-Control-Allow-Headers,Content-Type",
        },
      })
    .then(handleResponse)
    .catch(handleError);
}

const repoUrl =
  "https://microsoftit.visualstudio.com/3d1a556d-2042-4a45-9dae-61808ff33d3b/_apis/git/Repositories";
export function getRepo(bearerToken: string) {
  
   return fetch(repoUrl, {
        method: "GET", // POST for create, PUT to update when id already exists.
        headers: {
          Authorization: bearerToken,
          "content-type": "application/json",
          'Access-Control-Allow-Headers': "Access-Control-Allow-Headers,Content-Type",
        },
      })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllFilesForRepo(bearerToken: string, id: any) {

    return fetch(repoUrl + "/" + id + "/" + "/items?recursionLevel=full", {
        method: "GET", // POST for create, PUT to update when id already exists.
        headers: {
          Authorization: bearerToken,
          "content-type": "application/json",
          'Access-Control-Allow-Headers': "Access-Control-Allow-Headers,Content-Type",
        },
      })
    .then(handleResponse)
    .catch(handleError);
}

const csProj =
  "https://microsoftit.visualstudio.com/3d1a556d-2042-4a45-9dae-61808ff33d3b/_apis/git/repositories/dcd4a6d8-4789-468a-a59d-01b088d634c6/items//Fin.Tax.TaxReporting.TAPUIServices/Fin.Tax.TaxReporting.TAPUIBLL/Fin.Tax.TaxReporting.TAPUIBLL.csproj?versionType=Branch&versionOptions=None&includeContent=true";
export function getcsProjContent(bearerToken: string, id: any, path: any) {
  return fetch(
    repoUrl +
      "/" +
      id +
      "/" +
      "/items/" +
      path +
      "?versionType=Branch&versionOptions=None&includeContent=true",
    {
      method: "GET", // POST for create, PUT to update when id already exists.
      headers: {
        Authorization: bearerToken,
        "content-type": "application/json",
        'Access-Control-Allow-Headers': "Access-Control-Allow-Headers,Content-Type",
      },
    }
  )
    .then((data) => {
      return data.text();
    })
    .catch(handleError);
}
