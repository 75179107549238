import { handleResponse, handleError } from "../../../api/api.util";
import { Configurations } from "../../../App.global";
import { GetToken } from "../../../auth/authorization-token";

const uiservicesURL = Configurations.baseURL + "/DataAnalysisController/";

// Create or Update an activity
export function getFeedloadDataInsights(id: any) {
 return GetToken()
    .then((res) => {
      let bearerToken = res;
      var serviceURL = uiservicesURL + "GetFeedLoadInsights?feedLoadId=" + id;
      return fetch(serviceURL, {
        method: "GET", // POST for create, PUT to update when id already exists.
        headers: {
          Authorization: bearerToken,
          "content-type": "application/json",
        },
      });
    })
    .then(handleResponse)
    .catch(handleError);
}

// Create or Update an activity
export function getAuditDetails(id: any) {
  return GetToken()
    .then((res) => {
      let bearerToken = res;
      return fetch(
        uiservicesURL + "GetInterstageAuditComparisonLog?auditRunId=" + id,
        {
          method: "GET", // POST for create, PUT to update when id already exists.
          headers: {
            Authorization: bearerToken,
            "content-type": "application/json",
          },
        }
      );
    })
    .then(handleResponse)
    .catch(handleError);
}
