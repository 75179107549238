export interface IActivities {
  Activity: string;
  Status: string; // make it enum
  TimeTakenToCompleteInMinutes: number;
  TotalRows: number;
  FailedRows: number;
  PercentageCompleted: number;
  ListSubActivityRun: ISubActivity[];
  StartTime: string;
  ActivityDetails: string;
  LineageId: string;
  FileName: string;
}

export interface ISubActivity {
  ActivityRunID: number;
  SubActivityName: string;
  ProcessStatus: string; //make it enum
  SubActivityRunComments?: string;
  ElapsedTime: string;
  ListAuditRuns: IAuditActivity[];
  CreatedDate: string;
  UpdatedDate: string;
  JobId: string;
}

export interface IAuditActivity {
  AuditRunID: number;
  QueryID: number;
  Status: string;
  CreatedDate: string;
  ElapsedTime: string;
  UpdatedBy: string;

  datasource?: string;
  reportingperiod?: string;
  transactionperiod?: string;
  sourcetablename?: string;
  targettablename?: string;
  feedloadid?: number;
  auditruniddup?: number;
  matchcondition?: string;
  totalrecords?: string;
}

export class AuditActivity implements IAuditActivity {
  AuditRunID: number = 0;
  QueryID: number = 0;
  Status: string = "";
  CreatedDate: string = "";
  ElapsedTime: string = "";
  UpdatedBy: string = "";
  datasource?: string | undefined;
  reportingperiod?: string | undefined;
  transactionperiod?: string | undefined;
  sourcetablename?: string | undefined;
  targettablename?: string | undefined;
  feedloadid?: number | undefined;
  auditruniddup?: number | undefined;
  matchcondition?: string | undefined;
  totalrecords?: string | undefined;
}
