import { ITrainingVideoPlaylist } from "./model/ITrainingVideo";

export const trainingVideosList: ITrainingVideoPlaylist[] = [
	{
		name: "OneTax Videos",
		videoList: [
			{
				name: "Acquire",
				link:
					"https://msit.microsoftstream.com/embed/video/90267be3-a4b6-4152-b399-e7b07f17acf0?autoplay=false&showinfo=true",
			},
			{
				name: "Import",
				link:
					"https://msit.microsoftstream.com/embed/video/69a726e9-740a-4e90-9a96-e98a5cfef76e?autoplay=false&showinfo=true",
			},
			{
				name: "Release Management",
				link:
					"https://msit.microsoftstream.com/embed/video/58747896-5bfe-4fff-8bcd-daa5298c15af?autoplay=false&showinfo=true",
			},
			{
				name: "MSRA Run Steps",
				link:
					"https://msit.microsoftstream.com/embed/video/a4151b72-14b2-4f95-ac37-a8b0463f74f3?autoplay=false&showinfo=true",
			},
			{
				name: "TAP Reports and Mart",
				link:
					"https://msit.microsoftstream.com/embed/video/1ab40840-98dc-8491-90e2-f1ec36759d59?autoplay=false&showinfo",
			},
			{
				name: "Customer PII Early Design Discussion",
				link:
					"https://msit.microsoftstream.com/embed/video/1ab40840-98dc-8491-90e9-f1ec36759d59?autoplay=false&showinfo=true",
			},
			{
				name: "TAP UI Walkthrough",
				link:
					"https://msit.microsoftstream.com/embed/video/12860840-98dc-8491-bd32-f1ec3676266a?autoplay=false&showinfo=true",
			},
		],
	},
	{
		name: "Design Demos",
		videoList: [
			{
				name: "Transform Engine",
				link:
					"https://msit.microsoftstream.com/embed/video/fbf8a0ff-0400-a521-e230-f1eadb22c567?autoplay=false&showinfo=true",
			},
			{
				name: "SAP VAT Report",
				link:
					"https://msit.microsoftstream.com/embed/video/391ba1ff-0400-a521-c39b-f1eacff8b69f?autoplay=false&showinfo=true",
			},
		],
	},
];
