import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./component-container.scss";
import Dashboard from "../../dashboard/dashboard";
import menuList from "../application-container/application-menu-data";
const ComponentContainer = (props) => {
  return (
    <div className="component-container">
      <div className="component-placeholder">
        <Switch>
          <Route exact path="/home" component={Dashboard}></Route>

          {menuList.map((parent) => {
            return parent.routeList.map((child) => {
              return (
                <Route
                  exact
                  path={child.url}
                  component={child.component}
                ></Route>
              );
            });
          })}
          <Redirect from="/" to="/home" /> 
        </Switch>
      </div>
    </div>
  );
};
export default ComponentContainer;
