import rootReducer from "./reducers";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { AppActions } from "./types/app-actions";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export type AppState = ReturnType<typeof rootReducer>;

const configureStore = (initalState: AppState) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    rootReducer,
    initalState,
    composeEnhancers(
      applyMiddleware(
        reduxThunk as ThunkMiddleware<AppState, AppActions>, // To be analysied further
        reduxImmutableStateInvariant()
      )
    )
  );
};

export default configureStore;
