import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import "./onboarding.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  root: {
    padding: 8,
    margin: 16,
    overflow: "auto",
    //background: "grey",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export function OnBoardingComponent() {
  const classes = useStyles();
  return (
    <>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <h1>
              Welcome to Tax Team !!!
              <Divider />
            </h1>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2>(Silos/SAW/SC-ALT)</h2>
              </AccordionSummary>
              <AccordionDetails>
                <h4>
                  <div>1. Join Silo:</div>
                  <div className="subpoints">
                    <ul>
                      <li>
                        Go to{" "}
                        <a href="https://sasweb.microsoft.com/" target="_blank">
                          SASWeb
                        </a>
                      </li>
                      <li>Click on Join silo</li>
                      <li>Search by silo name: TaxEnggAdmins </li>
                      <li>Join this above silo.</li>
                    </ul>
                  </div>
                  <div>
                    2. Once SAW machine is assigned to you, you can check its
                    details by going to :{" "}
                    <a href="https://sasweb.microsoft.com/" target="_blank">
                      SASWeb
                    </a>{" "}
                    and click on My SAW.
                  </div>
                  <div>3. Request for SC-Alt account:</div>
                  <div className="subpoints">
                    <ul>
                      <li>
                        Go to{" "}
                        <a href="https://myaccess/" target="_blank">
                          MyAccess
                        </a>
                      </li>
                      <li>
                        Click on "Manage Accounts" Tile on MyAccess Home Page.
                      </li>
                      <li>
                        Select "Request New Account" in Manage Smart Card
                        Accounts section, provide Domain info(fareast) where you
                        want the Smart Carded Alternate account to be created,
                        you can select multiple domains based on your
                        requirement.
                      </li>
                      <li>
                        Review your request for creation of Smart Carded
                        Alternate account and click on Submit.
                      </li>
                      <li>
                        You will be returned to the Home Page. A message will be
                        displayed at the top of the page that your request is
                        being processed. You will receive an email from MyAccess
                        Communications alias providing information about your
                        submission.
                      </li>
                    </ul>
                  </div>

                  <div>
                    4. Once SC-Alt account is created, then you can request for
                    SC-Alt card:
                  </div>
                  <div className="subpoints">
                    <ul>
                      <li>
                        Go to :{" "}
                        <a
                          href="https://gsamportalap/rightcrowd/"
                          target="_blank"
                        >
                          GSAM
                        </a>
                      </li>
                      <li>Click on: chip only smart card</li>
                      <li>Click on : New chip only smart card request</li>
                      <li>
                        Select the Alt alias option for the smart card type
                      </li>
                      <li>
                        Enter the Alt alias & Alt domain (You must have received
                        an email with the SC-Alt account details from the step 3
                        above)
                      </li>
                      <li> Submit the request</li>
                      <li>
                        You’ll receive an email from GSAM India
                        DIOINDIA@microsoft.com. It’ll take 3-4 days to receive
                        the SC-Alt card.
                      </li>
                    </ul>
                  </div>
                  <div>
                    Once SC-Alt card is received, Reach out to Tech link
                    desk(14th floor) to set the PIN for the SC-Alt card. Once
                    SAW laptop is setup, then join a silo : CloudEnterpriseALT
                    by following the steps from the point 1(above). SWEs to join
                    ‘ScAltTaxSWE’ SG (link) using SC-ALT identity only
                  </div>
                </h4>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2>Scrum Teams</h2>
              </AccordionSummary>
              <AccordionDetails>
                <h3>
                  <div>
                    <h4>Currently there are three scrum teams in Tax team :</h4>
                    <div>
                     
                      <a
                        href="https://microsoftit.visualstudio.com/OneITVSO/_sprints/taskboard/FIN-FFS-taxrp-Cygnus/OneITVSO/"
                        target="_blank"
                      >
                        Cygnus (Scrum Master : Tejas)
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://microsoftit.visualstudio.com/OneITVSO/_sprints/taskboard/FIN-FFS-taxrp-Grus/OneITVSO/"
                        target="_blank"
                      >
                        Grus (Scrum Master : Sheetal)
                      </a>
                    </div>
                    <div>
                      <a
                        href=" https://microsoftit.visualstudio.com/OneITVSO/_sprints/taskboard/FIN-FFS-taxrp-Anser/OneITVSO/"
                        target="_blank"
                      >
                        Anser (Scrum Master : Ajay)
                      </a>
                    </div>
                  </div>
                </h3>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2>Source Code</h2>
              </AccordionSummary>
              <AccordionDetails>
                <h3>
                  <div>
                    <div>
                      Azure DevOps link for the team:{" "}
                      <a
                        href="https://microsoftit.visualstudio.com/OneITVSO/_git/FIN-Tax-TaxRP-TAPSourceOnboard"
                        target="_blank"
                      >
                        FinTax
                      </a>
                    </div>
                    <div>
                      OneTax Site:{" "}
                      <a href="https://tapuidit.microsoft.com/" target="_blank">
                        TAPUI
                      </a>
                    </div>
                    <div>
                      For Repos List: refer{" "}
                      <a href="/utilities/nuget" target="parent">
                        Nuget Page
                      </a>
                    </div>
                  </div>
                </h3>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2>Environments</h2>
              </AccordionSummary>
              <AccordionDetails>
                <h3>
                  <div className="flex">
                    <div  className="padding">
                      <a
                        href=" https://tapuidit.microsoft.com/"
                        target="_blank"
                      >
                        DIT (Dev)
                      </a>
                    </div>
                    <div className="padding">
                      <a
                        href=" https://tapuisit.microsoft.com/"
                        target="_blank"
                      >
                        SIT (Integration)
                      </a>
                    </div>
                    <div className="padding">
                      <a
                        href=" https://tapuiuat.microsoft.com/"
                        target="_blank"
                      >
                        UAT (Test)
                      </a>
                    </div>
                    <div className="padding">
                      <a
                        href=" https://tapui.microsoft.com/"
                        target="_blank"
                      >
                        PROD (Production Live Site)
                      </a>
                    </div>
                  </div>
                </h3>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2>TAPUI Angular Setup</h2>
              </AccordionSummary>
              <AccordionDetails>
                <h3>
                  <div>
                    <p>
                      <div>
                      1. Install Node and npm https://nodejs.org/en/download/
                      https://phoenixnap.com/kb/install-node-js-npm-on-windows
                      </div>
                      <div>
                      2. Clone Repo and from the Repo Path open command Prompt
                      EX:C:\Users\user_name\source\repos\FIN-FFS-taxrp-TAPUI\Fin.Tax.TaxReporting.TAPUI
                      </div>
                      <div>
                      3. Npm commands to run during first time setup • npm
                      install -g vsts-npm-auth --registry
                      https://registry.npmjs.com --always-auth false •
                      vsts-npm-auth -config .npmrc 
                      It will lead to Msft
                      authentication(MFA).
                      Once authorized move to next step •
                      npm install @microsoftit/telemetry-extensions-ng4
                      </div>
                      <div>
                       4. run
                      command to include all the Packages • npm install 
                      </div>
                      <div>
                      5. run
                      command to open UI • ng serve --open 
                      5.1 During this step
                      if encountered with 'ng' is not recognized as an internal
                      or external command, operable program or batch file. Run
                      command to install global version • npm install -g
                      @angular/cli IMPORTANT: if you install angular cli
                      globally then post installation revert changes in master
                      and build again 
                      5.2 UI will open locally in edge. 
                      </div>
                      <div>
                      6. to fix issues command • npm audit fix
                      </div>
                      <div>
                      7. IMPORTANT: if you
                      install angular cli globally then post installation revert
                      changes in master and build again. If you copy
                      environment.dit.ts to environment.ts in the repo(pointing
                      local UI to DIT), do not commit environmental file
                      changes.
                      </div>
                    </p>
                  </div>
                </h3>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
