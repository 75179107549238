import { IMenuList } from "./model/IMenuLink";
import SwaggerLaunchPadComponent from "../../quick-links/launch-pad/launch-pad";
import TrainingComponent from "../../onboarding-kit/training/training";
import { OnBoardingComponent } from "../../onboarding-kit/prerequisite/onboarding-component";
import NugetComponent from "../../devtools/nuget/nuget.component";
import UnderConstruction from "../../common/under-construction/under-construction";
import FeedLoadViewerComponent from "../../zero-dri/feedload-viewer/feedload-viewer";
import ThirdPartyHelperDevToolsComponent from "../../devtools/third-party-helpers/third-party-helpers";
import DiscussionComponent from "../../devtools/discussion/discussion";
import ActivateRolesComponent from "../../zero-dri/activate-roles/activate-roles";
import TrainingVideosComponent from "../../onboarding-kit/training-videos/training-videos";

export const menuList: IMenuList[] = [
	{
		rootPath: "Tax OnBoarding Kit",
		defaultKey: "OnBoardingKit",
		icon: "OnboardingKitIcon",
		routeList: [
			{
				key: "prerequisite",
				name: "Prerequisite",
				url: "/onboarding-kit/prerequisite",
				component: OnBoardingComponent,
			},
			{
				key: "trainings",
				name: "Documentation",
				url: "/onboarding-kit/trainings",
				component: TrainingComponent,
			},
			{
				key: "training-videos",
				name: "Training Videos",
				url: "/onboarding-kit/training-videos",
				component: TrainingVideosComponent,
			},
		],
	},
	{
		rootPath: "Zero DRI",
		defaultKey: "ZeroDRI",
		icon: "ZeroDriIcon",
		routeList: [
			{
				key: "activateroles",
				name: "Activate Roles",
				url: "/zerodri/activateroles",
				component: ActivateRolesComponent,
			},
			// {
			// 	key: "thirdpartyhelpers",
			// 	name: "Third Party Helpers",
			// 	url: "/zerodri/thirdpartyhelpers",
			// 	component: UnderConstruction,
			// },
			// {
			// 	key: "adfmonitor",
			// 	name: "ADF Monitor",
			// 	url: "/zerodri/adfmonitor",
			// 	component: UnderConstruction,
			// },
			{
				key: "feedloadviewer",
				name: "Feed Load Viewer",
				url: "/zerodri/feedloadviewer",
				component: FeedLoadViewerComponent,
			},
			{
				key: "swagger",
				name: "Launch Pad",
				url: "/zerodri/swagger",
				component: SwaggerLaunchPadComponent,
			},
		],
	},
	{
		rootPath: "Developer Tools",
		defaultKey: "devtools",
		icon: "BuildIcon",
		routeList: [
			{
				key: "nuget",
				name: "Nuget Dependency Explorer",
				url: "/devtools/nuget",
				component: NugetComponent,
			},
			// {
			// 	key: "queuemessageformat",
			// 	name: "Queue Msg Format",
			// 	url: "/devtools/queuemessageformat",
			// 	component: UnderConstruction,
			// },
			// {
			// 	key: "secretmanager",
			// 	name: "Secret Manager",
			// 	url: "/devtools/secretmanager",
			// 	component: UnderConstruction,
			// },
			{
				key: "thirdpartyhelpers",
				name: "Third Party Helpers",
				url: "/devtools/thirdpartyhelpers",
				component: ThirdPartyHelperDevToolsComponent,
			},
			// {
			// 	key: "testautomation",
			// 	name: "Test Automation",
			// 	url: "/devtools/testautomation",
			// 	component: UnderConstruction,
			// },
			{
				key: "discussion",
				name: "Discussion Group",
				url: "/devtools/discussion",
				component: DiscussionComponent,
			},
		],
	},
	{
		rootPath: "Upcoming",
		defaultKey: "marketplace",
		icon: "MarketPlaceIcon",
		routeList: [
			{
				key: "dlv",
				name: "Data Lake Viewer",
				url: "/marketplace/dlv",
				component: UnderConstruction,
			},
			{
				key: "hackprojects",
				name: "Hack Projects",
				url: "/marketplace/hackprojects",
				component: UnderConstruction,
			},
			{
				key: "cocreate",
				name: "Co Create",
				url: "/marketplace/cocreate",
				component: UnderConstruction,
			},
			{
				key: "adfpowerapp",
				name: "ADF Power App",
				url: "/marketplace/adfpowerapp",
				component: UnderConstruction,
			},
			{
				key: "reports",
				name: "Reports",
				url: "/business/reports",
				component: UnderConstruction,
			},
		],
	}
	



	// {
	// 	rootPath: "Market Place",
	// 	defaultKey: "marketplace",
	// 	icon: "MarketPlaceIcon",
	// 	routeList: [
	// 		{
	// 			key: "dlv",
	// 			name: "Data Lake Viewer",
	// 			url: "/marketplace/dlv",
	// 			component: UnderConstruction,
	// 		},
	// 		{
	// 			key: "hackprojects",
	// 			name: "Hack Projects",
	// 			url: "/marketplace/hackprojects",
	// 			component: UnderConstruction,
	// 		},
	// 		{
	// 			key: "cocreate",
	// 			name: "Co Create",
	// 			url: "/marketplace/cocreate",
	// 			component: UnderConstruction,
	// 		},
	// 		{
	// 			key: "adfpowerapp",
	// 			name: "ADF Power App",
	// 			url: "/marketplace/adfpowerapp",
	// 			component: UnderConstruction,
	// 		},
	// 	],
	// },
	// {
	// 	rootPath: "Business",
	// 	defaultKey: "business",
	// 	icon: "BusinessIcon",
	// 	routeList: [
	// 		{
	// 			key: "reports",
	// 			name: "Reports",
	// 			url: "/business/reports",
	// 			component: UnderConstruction,
	// 		},
	// 	],
	// },
];

export default menuList;
