import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
	IActivities,
	ISubActivity,
	IAuditActivity,
	AuditActivity,
} from "./model/IFeedloadModel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CancelIcon from "@material-ui/icons/Cancel";
import { green, red, orange, blue, yellow } from "@material-ui/core/colors";
import { CircularProgressWithLabel } from "../../common/loading/circularprogress/circularprogress";
import PopoverComponent from "../../common/popover/popover";
import ScrollDialogComponent from "../../common/scroll-dialog/scroll-dialog-component";
import { getFeedloadDataInsights, getAuditDetails } from "./feedload-service";
import "./feedload-viewer.scss";
import { Loading } from "../../common/loading/loading";
import TextField from "@material-ui/core/TextField";
import { Fab, CircularProgress } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { DataGrid, GridRowData, GridColDef } from "@material-ui/data-grid";
import {
	formatDateTime,
	getDateAndTimeInLocalTime,
} from "../../../util/time-zone-util";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: "6px 16px",
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
}));

export default function FeedLoadViewerComponent() {
	const classes = useStyles();
	const [feedloadData, setFeedLoadData] = React.useState([]);
	const [loading, loadingState] = React.useState(false);
	const [searchText, setSearchText] = React.useState("");
	const [auditDataList, setAuditData] = React.useState<AuditActivity[]>([]);
	const [lineageId, setLineageId] = React.useState("");
	const [fileName, setFileName] = React.useState("");

	const getAuditData = (data: any) => {
		return new Promise((resolve) => {
			var list: AuditActivity[] = auditDataList;

			data.forEach((record: any) => {
				record.ListSubActivityRun?.forEach((s: any) => {
					var counter = 0;

					if (s.ListAuditRuns && s.ListAuditRuns.length > 0) {
						s.ListAuditRuns?.forEach((a: any) => {
							getAuditDetails(a.AuditRunID)
								.then((details) => {
									counter++;
									var auditData: AuditActivity = new AuditActivity();
									auditData.QueryID = a.QueryID;
									auditData.Status = a.Status;
									auditData.CreatedDate = a.CreatedDate;
									auditData.ElapsedTime = a.ElapsedTime;
									auditData.UpdatedBy = a.UpdatedBy;

									auditData.AuditRunID = a.AuditRunID;
									if (details != undefined && details.length > 0) {
										auditData.datasource = details[0].datasource;
										auditData.reportingperiod = details[0].reportingperiod;
										auditData.transactionperiod = details[0].transactionperiod;
										auditData.sourcetablename = details[0].sourcetablename;
										auditData.targettablename = details[0].targettablename;
										auditData.feedloadid = details[0].feedloadid;
										auditData.auditruniddup = details[0].auditrunid;
										a.matchcondition = details[0].matchcondition;
										auditData.totalrecords = details[0].totalrecords;
									}
									list.push(auditData);
									if (s.ListAuditRuns.length == counter) {
										s.ListAuditRuns = list;
										setLineageId(data[0].LineageId);
										setFileName(data[0].FileName);
										setAuditData(list);
										setFeedLoadData(data);
										loadingState(false);
									}
								})
								.catch((e) => {});
						});
					} else {
						setLineageId(data[0].LineageId);
						setFileName(data[0].FileName);
						setFeedLoadData(data);
						loadingState(false);
					}
				});
			});
			resolve(data);
		});
	};
	const getDetails = () => {
		loadingState(true);
		getFeedloadDataInsights(searchText)
			.then((data: any) => {
				//fetch audit data

				getAuditData(data).then((a) => {
					data = a;
				});
			})
			.finally(() => {});
	};

	const getLink = (activity: string, msg: string) => {
		switch (activity) {
			case "Acquire":
				return getAcquireADFLink(msg);
			case "Import":
				return getImportLink(msg);
		}
	};

	const getImportLink = (jobId: string) => {
		var job = jobId.substring(4, jobId.length);
		return (
			"https://taphdi.azurehdinsight.net/yarnui/hn/cluster/app/application_" +
			job
		);
	};
	const getAcquireADFLink = (msg: string) => {
		const adfParams =
			"?factory=%2Fsubscriptions%2Fd75945a5-535f-4626-9d68-075eedbcf430%2FresourceGroups%2F11814-OneTax-Prod-01%2Fproviders%2FMicrosoft.DataFactory%2Ffactories%2FTAPADFPROD";
		const adfURL = "https://ms-adf.azure.com/monitoring/pipelineruns/";

		// var index = msg.lastIndexOf("RunID ");
		// var mySubString = "";
		// if (index != -1) mySubString = msg.substring(index + 6, index + 42);
		return adfURL + msg + adfParams;
	};

	const getScrollComponent = (title: any, activityDetails: any) => {
		let data = { header: title, details: activityDetails };
		return <ScrollDialogComponent {...data}></ScrollDialogComponent>;
	};

	// grid

	const columns: GridColDef[] = [
		{ field: "AuditRunID", headerName: "AuditRunID", width: 150 },
		{ field: "QueryID", headerName: "QueryID", width: 150 },
		{ field: "Status", headerName: "Status", width: 150 },
		{ field: "datasource", headerName: "datasource", width: 150 },
		{ field: "reportingperiod", headerName: "reportingperiod", width: 150 },
		{ field: "transactionperiod", headerName: "transactionperiod", width: 150 },
		{ field: "sourcetablename", headerName: "sourcetablename", width: 150 },
		{ field: "feedloadid", headerName: "feedloadid", width: 150 },
		{ field: "auditruniddup", headerName: "auditruniddup", width: 150 },
		{ field: "matchcondition", headerName: "matchcondition", width: 150 },
		{ field: "totalrecords", headerName: "totalrecords", width: 150 },
		{ field: "CreatedDate", headerName: "CreatedDate", width: 150 },
		{ field: "ElapsedTime", headerName: "ElapsedTime", width: 150 },
		{ field: "UpdatedBy", headerName: "UpdatedBy", width: 150 },
	];

	const getAuditGridRows = (data: IAuditActivity[]) => {
		const rows: GridRowData = [];
		let rowNumber = 0;

		data.forEach((e) => {
			rows.push({
				id: rowNumber++,
				AuditRunID: e.AuditRunID,
				QueryID: e.QueryID ?? "",
				Status: e.Status ?? "",
				datasource: e.datasource ?? "",
				reportingperiod: e.reportingperiod ?? "",
				transactionperiod: e.transactionperiod ?? "",
				sourcetablename: e.sourcetablename ?? "",
				feedloadid: e.feedloadid ?? "",
				auditruniddup: e.auditruniddup ?? "",
				matchcondition: e.matchcondition ?? "",
				totalrecords: e.totalrecords ?? "",
				CreatedDate: e.CreatedDate ?? "",
				ElapsedTime: e.ElapsedTime ?? "",
				UpdatedBy: e.UpdatedBy ?? "",
			});
		});
		debugger;
		return rows;
	};

	return (
		<>
			{
				<div className="feedload-timeline">
					<div className="one-line">
						<TextField
							id="outlined-required"
							label="Feed Load Id "
							defaultValue={searchText}
							variant="outlined"
							onChange={(event) => {
								setSearchText(event.target.value);
							}}
						/>
						<Fab
							variant="extended"
							onClick={() => {
								getDetails();
							}}
						>
							Populate
						</Fab>
						{loading && <CircularProgress color="secondary" />}
						<fieldset>
							<legend>Help</legend>
							<div className="header-button">
								<a
									className="header-button"
									href="https://tapcfscluster.azurehdinsight.net/yarnui/hn/cluster"
									target="_blank"
								>
									CFS HDInsight Dashboard
								</a>
							</div>
							<div className="header-button">
								<a
									className="header-button"
									href="https://taphdi.azurehdinsight.net/yarnui/hn/cluster"
									target="_blank"
								>
									HDInsight Dashboard
								</a>
							</div>

							<div className="header-button">
								<a
									className="header-button"
									href="https://taphdi.azurehdinsight.net/#/main/view/TEZ/tez_cluster_instance?rowCount=10"
									target="_blank"
								>
									Tez View
								</a>
							</div>
							<div className="header-button">
								<a
									className="header-button"
									href="https://taphdi.azurehdinsight.net/yarnui/hn/cluster/app/application_1606402759650_0011"
									target="_blank"
								>
									Application View
								</a>
							</div>

							<div className="header-button">
								<a
									className="header-button"
									href="https://ms.portal.azure.com/#@microsoft.onmicrosoft.com/resource/subscriptions/d75945a5-535f-4626-9d68-075eedbcf430/resourceGroups/11814-OneTax-Prod-01/providers/microsoft.insights/components/tapappinsight/searchV2"
									target="_blank"
								>
									App Insight
								</a>
							</div>
						</fieldset>
					</div>
					{!loading && feedloadData && feedloadData.length > 0 && (
						<>
							<div>
								<h3>LineageId: {lineageId}</h3>
							</div>
							<div>
								<h3>FileName: {fileName}</h3>
							</div>
							<Timeline align="left">
								{feedloadData.map((activity: IActivities) => {
									return (
										<>
											<TimelineItem>
												<TimelineOppositeContent>
													<Paper elevation={5} className={classes.paper}>
														<div className="paper-padding">
															<Typography>
																Start time :{" "}
																<b>
																	{getDateAndTimeInLocalTime(
																		new Date(activity.StartTime)
																	)}
																</b>
															</Typography>
															<Typography>
																Total time taken to complete :
																<b>
																	{" "}
																	<h2>
																		{activity.TimeTakenToCompleteInMinutes}
																	</h2>
																</b>
															</Typography>
														</div>
													</Paper>
													<br></br>
												</TimelineOppositeContent>
												<TimelineSeparator>
													<TimelineDot>
														<div className="activity-name">
															{activity.Activity}
														</div>
														<br></br>
														{activity.Status == "Pending" && (
															<HourglassEmptyIcon
																fontSize="large"
																style={{ color: yellow[500] }}
															/>
														)}
														{activity.Status == "InProgress" && (
															<AutorenewIcon
																fontSize="large"
																style={{ color: blue[500] }}
															/>
														)}
														{activity.Status == "Success" && (
															<CheckCircleIcon
																fontSize="large"
																style={{ color: green[500] }}
															/>
														)}
														{activity.Status == "Failed" && (
															<ErrorIcon
																fontSize="large"
																style={{ color: red[500] }}
															/>
														)}
														{activity.Status == "Terminated" && (
															<CancelIcon
																fontSize="large"
																style={{ color: orange[500] }}
															/>
														)}
														<div className="activity-name">
															{activity.PercentageCompleted}%
														</div>
													</TimelineDot>
													<TimelineConnector />
												</TimelineSeparator>
												<TimelineContent>
													<Paper elevation={5} className={classes.paper}>
														<div className="one-line">
															<div>
																<Typography>
																	Total Rows : {activity.TotalRows}
																</Typography>

																<Typography>
																	Failed Rows : {activity.FailedRows}
																</Typography>
															</div>
															<div className="paper-padding">
																{activity &&
																	activity.ActivityDetails &&
																	getScrollComponent(
																		activity.Activity,
																		activity.ActivityDetails
																	)}
															</div>
														</div>
													</Paper>
													<br></br>
												</TimelineContent>
											</TimelineItem>
											{activity.ListSubActivityRun.map(
												(subActivity: ISubActivity) => {
													return (
														<>
															<TimelineItem>
																<TimelineOppositeContent>
																	<Paper
																		elevation={1}
																		className={classes.paper}
																	>
																		<Typography>
																			Created date :{" "}
																			<b>
																				{getDateAndTimeInLocalTime(
																					new Date(subActivity.CreatedDate)
																				)}
																			</b>{" "}
																			/ Updated date :{" "}
																			<b>
																				{getDateAndTimeInLocalTime(
																					new Date(subActivity.UpdatedDate)
																				)}
																			</b>
																		</Typography>

																		<Typography>
																			Elapsed time :{" "}
																			<b>
																				<h3>{subActivity.ElapsedTime}</h3>
																			</b>
																		</Typography>
																		<br></br>
																	</Paper>
																</TimelineOppositeContent>
																<TimelineSeparator>
																	{/* <TimelineDot> */}
																	<b>
																		<div className="activity-name">
																			{subActivity.SubActivityName}
																		</div>
																	</b>
																	{subActivity.ProcessStatus == "Pending" && (
																		<HourglassEmptyIcon
																			fontSize="small"
																			style={{ color: yellow[500] }}
																		/>
																	)}
																	{subActivity.ProcessStatus ==
																		"InProgress" && (
																		<AutorenewIcon
																			fontSize="small"
																			style={{ color: blue[500] }}
																		/>
																	)}
																	{subActivity.ProcessStatus == "Success" && (
																		<CheckCircleIcon
																			fontSize="small"
																			style={{ color: green[500] }}
																		/>
																	)}
																	{subActivity.ProcessStatus == "Failed" && (
																		<ErrorIcon
																			fontSize="small"
																			style={{ color: red[500] }}
																		/>
																	)}
																	{subActivity.ProcessStatus ==
																		"Terminated" && (
																		<ErrorIcon
																			fontSize="small"
																			style={{ color: red[500] }}
																		/>
																	)}
																	{/* </TimelineDot> */}
																	<TimelineConnector />
																</TimelineSeparator>
																<TimelineContent>
																	{
																		<div className="paper-padding">
																			<Paper
																				elevation={1}
																				className={classes.paper}
																			>
																				<Typography>
																					<div className="one-line">
																						{subActivity.SubActivityName.includes(
																							"Audit"
																						) && (
																							<div>
																								{" "}
																								ADB Job ids :{" "}
																								{subActivity.JobId}
																							</div>
																						)}
																						{!subActivity.SubActivityName.includes(
																							"Audit"
																						) && (
																							<div>
																								<a
																									className="header-button"
																									href={getLink(
																										activity.Activity,
																										subActivity.JobId ?? ""
																									)}
																									target="_blank"
																								>
																									Job Link
																								</a>
																							</div>
																						)}
																						<div className="paper-padding">
																							{subActivity &&
																								subActivity.SubActivityRunComments &&
																								getScrollComponent(
																									subActivity.SubActivityName,
																									subActivity.SubActivityRunComments
																								)}
																						</div>
																					</div>
																				</Typography>
																				{subActivity.ListAuditRuns &&
																					subActivity.ListAuditRuns.length >
																						0 && (
																						<div
																							style={{
																								height: 300,
																								width: "100%",
																							}}
																						>
																							{/* <DataGrid
                                                rows={getAuditGridRows(
                                                  subActivity.ListAuditRuns
                                                )}
                                                columns={columns}
                                              /> */}
																						</div>
																					)}

																				<br></br>
																			</Paper>
																		</div>
																	}
																</TimelineContent>
															</TimelineItem>
															{/* {subActivity.ListAuditRuns.map(
                              (audit: IAuditActivity) => {
                                return (
                                  <>
                                    <TimelineItem>
                                      <TimelineOppositeContent>
                                        <Paper
                                          elevation={3}
                                          className={classes.paper}
                                        >
                                          <Typography>
                                            Run Id : {audit.AuditRunID}
                                          </Typography>
                                          <Typography>
                                            Query Id : {audit.QueryID}
                                          </Typography>
                                          <Typography>
                                            Ran by : {audit.UpdatedBy}
                                          </Typography>
                                        </Paper>
                                      </TimelineOppositeContent>
                                      <TimelineSeparator>
                                        Audit
                                        <TimelineDot>
                                          {audit.Status == "Success" && (
                                            <CheckCircleIcon
                                              fontSize="small"
                                              style={{ color: green[500] }}
                                            />
                                          )}
                                          {audit.Status == "Failed" && (
                                            <ErrorIcon
                                              fontSize="small"
                                              style={{ color: red[500] }}
                                            />
                                          )}
                                          {audit.Status == "Terminated" && (
                                            <ErrorIcon
                                              fontSize="small"
                                              style={{ color: red[500] }}
                                            />
                                          )}
                                        </TimelineDot>
                                        <TimelineConnector />
                                      </TimelineSeparator>
                                      <TimelineContent>
                                        {audit.datasource &&
                                          audit.datasource.length > 0 && (
                                            <Paper
                                              elevation={3}
                                              className={classes.paper}
                                            >
                                              <Typography>
                                                DataSource: {audit.datasource}
                                              </Typography>
                                              <Typography>
                                                Reportingperiod:{" "}
                                                {audit.reportingperiod}
                                              </Typography>
                                              <Typography>
                                                Transactionperiod{" "}
                                                {audit.transactionperiod}
                                              </Typography>
                                              <Typography>
                                                Sourcetablename{" "}
                                                {audit.sourcetablename}
                                              </Typography>
                                              <Typography>
                                                Targettablename{" "}
                                                {audit.targettablename}
                                              </Typography>
                                              <Typography>
                                                feedloadid: {audit.feedloadid}
                                              </Typography>
                                              <Typography>
                                                Auditruniddup:{" "}
                                                {audit.auditruniddup}
                                              </Typography>
                                              <Typography>
                                                Matchcondition:{" "}
                                                {audit.matchcondition}
                                              </Typography>
                                              <Typography>
                                                Totalrecords:{" "}
                                                {audit.totalrecords}
                                              </Typography>
                                              <br></br>
                                            </Paper>
                                          )}
                                      </TimelineContent>
                                    </TimelineItem>
                                  </>
                                );
                              }
                            )} */}
														</>
													);
												}
											)}
										</>
									);
								})}
							</Timeline>
						</>
					)}
				</div>
			}
		</>
	);
}
