import React from "react";
import CardComponent from "../../common/card/cardcomponent";
import Loading from "../../common/loading/loading";
import { devToolsThirdPartyHelperList } from "./third-party-helpers-data";
import "./third-party-helpers.scss";

export default function ThirdPartyHelperDevToolsComponent() {
	return (
		<div className="flex-container">
			{devToolsThirdPartyHelperList.map((data) => {
				return (
					<CardComponent
						title={data.name}
						description={data.description}
						url={data.link}
						image={data.imageurl}
						imagetitle={data.imagetitle}
					></CardComponent>
				);
			})}
		</div>
	);
}
