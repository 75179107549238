import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./launch-pad.scss";
import swaggerImage from "../../../assests/image/jpg/swagger.png";
import defaultLaunch from "../../../assests/image/jpg/default-launch.png";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import DataLake from "../../../assests/image/jpg/date-lake.jpg";
import Report from "../../../assests/image/jpg/Report.png";
import {
	InputLabel,
	Select,
	MenuItem,
	Button,
	withStyles,
	InputBase,
} from "@material-ui/core";
import { GetSwaggerLinkData, GetLaunchPadData } from "./launch-pad-service";
import { Loading } from "../../common/loading/loading";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 350,
		maxHeight: 550,
	},
	media: {
		height: 100,
		paddingTop: "56.25%", // 16:9
		padding: 8,
		width: 330,
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	avatar: {
		backgroundColor: red[500],
	},
}));

const BootstrapInput = withStyles((theme) => ({
	root: {
		"label + &": {
			marginTop: theme.spacing(1),
		},
	},
	input: {
		width: 200,
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		fontSize: 16,
		padding: "10px 26px 10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
	},
}))(InputBase);

var firstPageLoadSwaggerData = [];
var firsttimeSwagger = true;
var firstPageLoadLaunchPadData = [];
var firsttimeLaunchPad = true;
export default function SwaggerLaunchPadComponent() {
	const classes = useStyles();

	const [env, onEnvChange] = React.useState("");
	const [link, onLinkChange] = React.useState("");
	const [SwaggerLinkData, setSwaggerLinkData] = React.useState([]);
	const [LaunchPadData, setLaunchPadData] = React.useState([]);

	const [loading, loadingState] = React.useState(false);

	function isArray(value) {
		return Array.isArray(value);
	}
	useEffect(() => {
		if (firsttimeSwagger) {
			loadingState(true);
			GetSwaggerLinkData().then((data) => {
				if (isArray(data)) {
					setSwaggerLinkData(data);
					firstPageLoadSwaggerData = data;
					firsttimeSwagger = false;
				}
				loadingState(false);
			})
			.catch((e)=>{
				loadingState(false);
			});
		} else setSwaggerLinkData(firstPageLoadSwaggerData);
	}, []); // GET request only once, no need to compute everytime
	useEffect(() => {
		if (firsttimeLaunchPad) {
			GetLaunchPadData().then((data) => {
				if (isArray(data)) {
					for (let i = 0; i < data.length; i++) {
						if (data[i].image == "Report") data[i].image = Report;
						if (data[i].image == "DataLake") data[i].image = DataLake;
					}
					setLaunchPadData(data);
					firstPageLoadLaunchPadData = data;
					firsttimeLaunchPad = false;
				}
			});
		} else setLaunchPadData(firstPageLoadLaunchPadData);
	}, []); // GET request only once, no need to compute everytime
	var envList = [];
	SwaggerLinkData.forEach((a) => {
		envList.push(a.environment);
	});

	const getLink = () => {
		var id = link.value;
		if (id != null && id != undefined) {
			var envVar = env;
			var envData = SwaggerLinkData.filter((a) => {
				return a.environment == envVar;
			})[0];
			var linkObj = envData.links.filter((b) => {
				return b.id == id;
			});
			return linkObj[0].link;
		}
		return "";
	};

	return (
		<>
		{loading && <Loading></Loading>}
		{!loading && 
		<div className="display-flex">
			<div className="swagger-card">
				<Card className={classes.root}>
					<CardHeader
						avatar={
							<>
								<Avatar aria-label="recipe" className={classes.avatar}>
									TAP
								</Avatar>
							</>
						}
						title="SWAGGER"
						action={
							<>
								<span>Env : </span>
								<Select
									labelId="demo-customized-select-label"
									id="demo-customized-select-1"
									value={env}
									onChange={(event) => {
										onEnvChange(event.target.value);
									}}
								>
									{envList.map((a) => {
										return <MenuItem value={a}>{a}</MenuItem>;
									})}
								</Select>
							</>
						}
					/>
					<CardMedia
						className={classes.media}
						image={swaggerImage}
						title="swagger"
					/>
					<CardContent>
						<Typography variant="body2" color="textSecondary" component="p">
							<h3>Collection of all TAP swagger links</h3>
						</Typography>
					</CardContent>
					<CardActions disableSpacing>
						<Select
							labelId="demo-customized-select-label"
							id="new1"
							value={link.name}
							onChange={(event) => {
								onLinkChange(event.target);
							}}
							input={<BootstrapInput />}
						>
							{SwaggerLinkData.map((a) => {
								if (a.environment == env) {
									return a.links.map((b) => {
										return <MenuItem value={b.id}>{b.name}</MenuItem>;
									});
								}
							})}
						</Select>
						&nbsp; &nbsp;
						<Button
							className="populate-button"
							variant="contained"
							color="primary"
							onClick={() => {
								var linkVal = getLink();
								if (linkVal != undefined && linkVal != "") {
									window.open(linkVal);
								}
							}}
							disabled={link == ""}
						>
							Launch
						</Button>
					</CardActions>
				</Card>
			</div>
			{LaunchPadData.map((a) => {
				return (
					<div className="swagger-card">
						<Card className={classes.root}>
							<CardHeader
								avatar={
									<>
										<Avatar aria-label="recipe" className={classes.avatar}>
											{a.initial}
										</Avatar>
									</>
								}
								title={a.name}
								action={
									<>
										{a.hasEnv && <span>Env : </span>}
										{a.hasEnv && (
											<Select
												labelId="demo-customized-select-label"
												id="demo-customized-select-1"
												value={env}
												// onChange={(event) => {
												//   onLinkEnvChange(event.target.value);
												// }}
											>
												{/* {envLinkMap.map((c) => {
                          return <MenuItem value={c.env}>{c.env}</MenuItem>;
                        })} */}
											</Select>
										)}
									</>
								}
							/>
							<CardMedia
								className={classes.media}
								image={a.image}
								title="swagger"
							/>
							<CardContent>
								<Typography variant="body2" color="textSecondary" component="p">
									<h3>{a.description}</h3>
								</Typography>
							</CardContent>
							<div className="launch">
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										if (a.hasEnv == false) {
											window.open(a.links[0].link);
										}
									}}
									disabled={a.hasEnv == true}
								>
									Launch
								</Button>
							</div>
						</Card>
					</div>
				);
			})}
		</div>}
		</>
	);
}
