import * as React from "react";
import "./iframe-component.scss";

export default function IFrameComponent(data: any) {
	return (
		<>
			<div className="iframe">
				<iframe
					width="330px"
					height="250px"
					src={data.src}
					allowFullScreen
					name={data.name}
					frameBorder="0"
				></iframe>
				<div className="iframe-name">
					<b>{data.name}</b>
				</div>
			</div>
		</>
	);
}
